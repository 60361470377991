.cdk-overlay-container {
  z-index: $dialog-z-index !important;
}

.cdk-overlay-dark-backdrop {
  backdrop-filter: blur(5px) !important;
  background: rgba($fut-primary-500-rgb, 0.6);
}

.cdk-overlay-backdrop {
  backdrop-filter: blur(5px) !important;
  background: rgba(44, 46, 59, 0.6) !important;
}
$directions: ("t":"top", "r": "right", "b" :"bottom", "l" :"left");

@each $name, $space in $fut-spacings {
  .fut-m-#{$name} {
    margin: $space !important;
  }

  .fut-p-#{$name} {
    padding: $space !important;
  }

  .fut-gap-#{$name} {
    gap: $space !important;
  }

  @each $direction-name, $direction in $directions {
    .fut-m-#{$direction-name}-#{$name} {
      margin-#{$direction}: $space !important;
    }

    .fut-p-#{$direction-name}-#{$name} {
      padding-#{$direction}: $space !important;
    }
  }

}

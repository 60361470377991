@use '@angular/material' as mat;
@use "@futura/futura-ui/styles/material-customization/material-variable" as var;

.fut-input-search {
  --border-color: var(--mdc-outlined-text-field-outline-color, --fut-gray-200);
  --border-width: 1px;
  --border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: calc(2px - var(--border-width)) calc(2.5px - var(--border-width));

  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);

  mat-icon {
    margin-right: 0.2em;
  }

  input {
    border: none;
    outline: none;
    padding-left: 0 !important;
    border-radius: var(--border-radius);
  }

  &:hover {
    --border-color: var(--fut-primary-500);
  }

  &:has(input:focus) {
    --border-color: #{mat.get-theme-color(var.$primary-accent-theme, primary)};
    --border-width: 2px;
  }
}

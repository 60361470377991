.fut-table-scroll {
  overflow: auto;
  align-items: flex-start !important;

  .fut-table thead th {
    position: sticky;
    top: 0;
    z-index: 9;
  }
}

.fut-table {
  width: 100%;
  border-spacing: 0px 1em;

  thead {
    color: #ababb1;

    th {
      background-color: $fut-secondary-200;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      color: black !important;

      ::selection {
        background-color: transparent;
        color: black !important;
      }

      &:has(.current) {
        background-color: $fut-secondary-400 !important;
      }

      &.text-center {
        .sortable {
          justify-content: center;
        }
      }

      &.text-right {
        .sortable {
          justify-content: flex-end;

          mat-icon {
            order: -1;
          }

          &.current {
            mat-icon {
              transform: translateX(-1em);
            }
          }

          &.inverse {
            mat-icon {
              transform: rotate(180deg);
            }
          }

          &:hover:not(.current) {
            mat-icon {
              transform: translateX(-1em);
            }
          }
        }
      }

      .sortable {
        width: 100%;
        cursor: pointer;
        display: flex;

        // justify-content: space-between
        align-items: center;

        mat-icon {
          display: none;
          width: 0 !important;
        }

        &.current {
          mat-icon {
            display: block;
          }
        }

        &.inverse {
          mat-icon {
            transform: rotate(180deg) translateX(-1em);
          }
        }

        &:hover:not(.current) {
          mat-icon {
            display: block;
            transform: none;
          }
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding-top: 0.8em;
        padding-bottom: 0.8em;

        hr {
          border-top-color: $fut-primary-500 !important;
        }
      }

      &:hover:not(.no-hover), &.current {
        background-color: $fut-secondary-50;
      }
    }
  }

  th, td {
    padding-left: 0.8em;
    padding-right: 0.8em;

    @media screen and (max-width: $fut-md) {
      padding-left: 0.2em;
      padding-right: 0.2em;
    }
  }
}

@use "sass:math";

@function toEm($value, $base-size: 14) {
  $remValue: math.div($value, $base-size) + em;
  @return $remValue;
}

$fut-xs-spacing-px: 4px;

$fut-xs-spacing: 0.29em;
$fut-s-spacing: 0.72em;
$fut-mobile-spacing: 1.43em;
$fut-default-spacing-desktop: 2.14em;

$fut-spacing-0: 0;
$fut-spacing-2: toEm(2);
$fut-spacing-4: toEm(4);
$fut-spacing-8: toEm(8);
$fut-spacing-12: toEm(12);
$fut-spacing-16: toEm(16);
$fut-spacing-24: toEm(24);
$fut-spacing-32: toEm(32);
$fut-spacing-40: toEm(40);
$fut-spacing-48: toEm(48);
$fut-spacing-64: toEm(64);
$fut-spacing-80: toEm(80);

$fut-spacings: (
  "0": $fut-spacing-0,
  "2": $fut-spacing-2,
  "4": $fut-spacing-4,
  "8": $fut-spacing-8,
  "12": $fut-spacing-12,
  "16": $fut-spacing-16,
  "24": $fut-spacing-24,
  "32": $fut-spacing-32,
  "40": $fut-spacing-40,
  "48": $fut-spacing-48,
  "64": $fut-spacing-64,
  "80": $fut-spacing-80,
);

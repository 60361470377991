mat-icon {
  cursor: pointer;
}

mat-icon.mat-icon {
  overflow: visible;
}

mat-icon.disabled {
  color: $fut-gray-400 !important;
}

progressbar.fut-progressbar {
  border-radius: 1em;

  @each $name in $subjects {
    &.#{$name} {
      .progress-bar {
        background: var(#{"--" + $name}) !important;
      }
    }
  }

  .progress-bar {
    height: 100%;
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
  }
}

progressbar.fut-progressbar {
  border-radius: 0 !important;
}

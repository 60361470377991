h1, .h1 {
  font-weight: bold;
  font-size: 1.64em;
  line-height: 1.1 !important;
  font-family: $fut-heading-family;
}

h2, .h2 {
  font-weight: bold;
  font-size: 1.4em;
  line-height: 1.4 !important;
  font-family: $fut-heading-family;

}

h3, .h3 {
  font-weight: bold;
  font-size: 1.21em;
  line-height: 1.21 !important;
  font-family: $fut-heading-family;

}

h4, .h4 {
  font-weight: bold;
  font-size: 1em;
  line-height: 1.1 !important;
  font-family: $fut-heading-family;
}

.fut-h1 {
  font-size: 1.64em;
  font-weight: 700;
  letter-spacing: -1px;
  font-family: $fut-heading-family;


  // Media query to only mobile
  @media screen and (max-width: $mobile) {
    font-size: 1.4em;
  }
}

.fut-h2 {
  font-size: 1.3em;
  font-weight: 700;
  letter-spacing: -1px;
  font-family: $fut-heading-family;

}

.fut-h3 {
  font-size: 1.3em;
  font-family: $fut-heading-family;

}

$font-weight: 700;

.fut-display-1 {
  font-size: 2.8em;
  font-weight: $font-weight;
  line-height: 1em;
}

.fut-display-2 {
  font-size: 2.2em;
  font-weight: $font-weight;
  line-height: 1em;
}

.fut-small {
  font-size: toEm(11) !important;
  line-height: 1.07em !important;
}

.fut-bold {
  font-weight: bold;
}

@mixin fut-small {
  font-size: toEm(11) !important;
  line-height: 1.07em !important;
}

@mixin fut-bold {
  font-weight: bold;
}

@each $name, $color in $fut-colors {
  .bg-#{$name} {
    background: $color !important;
  }
}

.bg-danger {
  background-color: rgba($fut-red-900-rgb, 0.1) !important;
  color: $fut-red-900 !important;
}

.bg-error {
  background-color: $fut-red-400 !important;
  color: white !important;
}

@each $name, $color in $fut-colors-rgb {
  .bg-#{$name}-opacity {
    background: rgba($color, 0.1) !important;
  }
}

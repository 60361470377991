@import 'buttons/color';

@import '@futura/futura-ui/styles/buttons';

.alice-btn {
  // --alice-background: #60BDFC linear-gradient(225deg, #181886 0%, #60BDFC 100%);
  // background: var(--alice-background);
  color: white;
  border: none !important;
  transition: all 0.3s ease-in-out !important;

  background: linear-gradient(100deg, #1b213c 0%, #003c70 100%);

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 500px;

  padding-left: 2em !important;
  padding-right: 2em !important;

  /* DropdownShadow */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  // aspect-ratio: 1/1 !important;

  min-width: none !important;
  max-width: none !important;
  min-height: none !important;
  max-height: none !important;

  span {
    overflow: hidden;
    white-space: nowrap;
    width: 0em;
    transition: all 0.3s ease-in-out;
  }

  .avatar-container {
    width: 0em;
    transition: all 0.3s ease-in-out;
    // aspect-ratio: 1/1;
  }

  &.disabled {
    background: $fut-gray-400 !important;
    color: rgba($fut-primary-500-rgb, 0.6) !important;
  }

  &:hover {
    span {
      width: 8em;
    }
    .avatar-container {
      // margin-left: 1em;
      width: 1.8em;
    }
    border-radius: 12px;
    padding-left: 2em !important;
    padding-right: 1.7em !important;
  }
}

// new
.fut-bottom-sheet {
  --bottom-sheet-radius: 16px;
  --x-padding: 16px;

  .mat-bottom-sheet-container {
    padding: 16px var(--x-padding) 32px !important;
    border-top-left-radius: var(--bottom-sheet-radius);
    border-top-right-radius: var(--bottom-sheet-radius);
    max-height: 95vh;
  }

  .mat-mdc-dialog-content {
    margin: 0 calc(var(--x-padding) * -1);
    padding: 0 var(--x-padding);
  }

  &.fut-bottom-sheet-h-100 {
    max-height: 100svh;
  }

  .cdk-overlay-container:has(&) {
    z-index: 9999999999;
  }
}

.cdk-overlay-container:has(.fut-alice-chat) {
  z-index: 9999999999;
}

.cdk-global-overlay-wrapper:has(.fut-alice-chat) {
  @media screen and (min-width: $fut-md) {
    justify-content: flex-end !important;

    > * {
      margin-right: 10em;
    }
  }
}

.fut-alice-chat {
  --paddingfut-dialog: 2.14em;
  --alice-height: 46em;

  .mat-bottom-sheet-container {
    padding: var(--paddingfut-dialog) var(--paddingfut-dialog) 0 !important;
    border-top-left-radius: var(--paddingfut-dialog) !important;
    border-top-right-radius: var(--paddingfut-dialog) !important;
    min-height: 46em;
    height: 46em;
    max-height: calc(100% - 5em) !important;
    min-width: min(26em, 100vw) !important;
    width: 26em;
    max-width: 100vw !important;

    @media screen and (max-width: $fut-md) {
      --paddingfut-dialog: 1.43em;
    }

    .mat-mdc-dialog-content {
      height: calc(var(--alice-height) - 5em);
      max-height: calc(var(--alice-height) - 5em);
    }
  }
}

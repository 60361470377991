.fut-btn {
  &.fut-btn-primary {
    background-color: $fut-primary-500;
    color: white;
    outline: none;
    border: none;

    &:hover {
      background-color: rgba(44, 69, 150, 1);
    }

    &[disabled], &.disabled {
      background-color: $fut-gray-400 !important;
      color: rgba($fut-primary-500-rgb, 0.6);
    }

    &.fut-btn-icon {
      mat-icon {
        opacity: 1;
      }
    }
  }

  &.fut-btn-secondary {
    background-color: $fut-secondary-800;
    color: white;
    outline: none;
    border: none;

    &[disabled], &.disabled {
      background-color: $fut-gray-400 !important;
      color: rgba($fut-primary-500-rgb, 0.6);
    }
  }

  &.fut-btn-tertiary {
    background-color: $fut-secondary-300;
    color: $fut-primary-500;
    outline: none;
    border: none;

    &:hover {
      background-color: $fut-secondary-400;
    }
  }

  &.fut-btn-valid {
    background-color: $fut-green-A700;
    color: white;
    outline: none;
    border: none;
  }

  &.fut-btn-whatsapp {
    background-color: rgba(77, 170, 87, 1) !important;
    color: white !important;
    border: none !important;
  }

  &.fut-btn-overflow {
    min-width: 0 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.fut-btn-link {
    color: $fut-primary-500;
    border: none;
    background-color: transparent;
    font-weight: bold;
    padding-left: 1em;
    padding-right: 1em;

    &:hover {
      background-color: $fut-secondary-200;
    }

    &:active {
      background-color: $fut-secondary-50;
    }

    &[disabled], &.disabled {
      color: $fut-gray-400 !important;
    }
  }

  &.fut-btn-link-dark {
    color: $fut-white;
    border: none;
    background-color: transparent;
    font-weight: bold;
    padding-left: 1em;
    padding-right: 1em;

    &:hover {
      background-color: $fut-primary-A400;
    }

    &:active {
      background-color: $fut-primary-A400;
    }

    &[disabled], &.disabled {
      color: $fut-gray-400 !important;
    }
  }

  &.fut-btn-danger {
    background-color: $fut-red-900;
    color: white;
    border: none;
  }

  &.fut-btn-text {
    background-color: transparent;
    color: $fut-primary-500;
    border: none;
  }

  &.fut-btn-icon {
    display: flex;
    justify-content: center;
    align-items: center;

    mat-icon, img {
      margin-right: 0.2em;
      z-index: 1;
    }
  }

  &.fut-btn-dropdown {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.5em;
    background-color: transparent;
    padding-left: 1em;
    padding-right: 1em;
    border: 1px solid rgba($fut-primary-500-rgb, 0.6);
    font-weight: normal;
    max-width: 15em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 9em;

    span {
      max-width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.fut-btn-outline {
    border: 1px solid rgba($fut-primary-500-rgb, 0.6);
    background-color: transparent !important;
    color: $fut-primary-500;
    pointer-events: all;
    z-index: 2;
    transition: all 0.1s ease-in-out;

    &:active:not([disabled]) {
      border-color: $fut-secondary-800;
      border-width: 2px;
      color: $fut-secondary-800;
    }

    mat-icon {
      z-index: 1;
      pointer-events: none;
    }

    &[disabled], &.disabled {
      color: rgba($fut-primary-500-rgb, 0.3) !important;
      border-color: rgba($fut-primary-500-rgb, 0.3) !important;
    }
  }

  &.fut-btn-outline-white {
    border: 1px solid white;
    color: white;
  }

  &.fut-btn-accent {
    background-color: $fut-accent-500;
    color: $fut-primary-500;
    outline: none;
    border: none;

    &[disabled], &.disabled {
      background-color: $fut-gray-400 !important;
      color: rgba($fut-primary-500-rgb, 0.6);
    }

    &:hover {
      background-color: $fut-accent-600;
    }

  }

  &.fut-btn-pill {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    padding: 0.3em;
    aspect-ratio: 1 / 1;
  }
}

.fut-segmented-buttons {
  width: 100%;
  display: flex;
  height: max-content;
  min-height: 2.6em;

  .fut-segmented-button {
    width: 100%;
    background-color: white;
    line-height: 1.2em;
    padding-bottom: 0.5em;
    padding-top: 0.5em;
    height: auto;
    min-height: 2.6em;
    font-weight: bold;
    border: 1px solid rgba(33, 37, 41, 0.6);
    transition: all 0.3s ease-in-out;

    &:first-child {
      border-top-left-radius: 3em;
      border-bottom-left-radius: 3em;
    }

    &:last-child {
      border-top-right-radius: 3em;
      border-bottom-right-radius: 3em;
    }

    &:not(:last-child) {
      border-right: none !important;
    }

    &.active {
      background-color: rgba($fut-secondary-800-rgb, 0.2) !important;
      border: 2px solid $fut-secondary-800 !important;
    }

    &:active, &:focus, &:hover {
      background-color: rgba($fut-secondary-800-rgb, 0.1);
      border: 1px solid $fut-secondary-800;
    }
  }

  .fut-segmented-button.active + .fut-segmented-button {
    border-left: none !important;
  }
}

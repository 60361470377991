.locked {
  position: relative;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(#fff, 0.6);
    z-index: 3;
    backdrop-filter: grayscale(100%);

    mat-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .circleIcon {
      transform: translate(-50%, -50%) scale(2.5);
    }
  }

  .overflow-auto {
    position: absolute;
    left: 0;
    width: 100%;
    max-height: 90%;
    padding-bottom: 10%;
  }
}

.lock-ui-hidden {
  &.directive-locked {
    filter: none !important;
  }

  .lock-cover {
    display: none !important;
  }
}

.directive-locked {
  filter: grayscale(1) !important;
  overflow: hidden !important;

  .lock-cover {
    display: flex;
  }
}

.lock-cover {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 1em;
  width: 100%;
  height: 100%;
  background-color: rgba(20, 20, 20, 0.4);
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 10;

  img {
    height: 40%;
    aspect-ratio: 1 / 1;
    filter: invert(1);
    transform-origin: top;

    &:hover {
      animation: swing 2s ease-in-out;
    }
  }

  &:hover {
    img {
      animation: swing 2s ease-in-out;
    }
  }
}

@keyframes swing {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(5deg);
  }

  20% {
    transform: rotate(-5deg);
  }

  30% {
    transform: rotate(3deg);
  }

  40% {
    transform: rotate(-3deg);
  }

  50% {
    transform: rotate(2deg);
  }

  60% {
    transform: rotate(-2deg);
  }

  70% {
    transform: rotate(1deg);
  }

  80% {
    transform: rotate(-1deg);
  }

  90%, 100% {
    transform: rotate(0deg);
  }
}

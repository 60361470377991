@import "../../../../global-styles";

.chip-scroll {
  display: flex;
  max-width: 100%;
  min-height: max-content;
  gap: 0.5em;
  overflow-x: auto;
  overflow-y: hidden;
}

.chip {
  display: flex;
  align-items: center;
  height: 2rem;
  padding: 0 0.5rem;
  border-radius: 1rem;
  min-height: 2em;
  gap: 0.3em;
  min-width: max-content;
  white-space: nowrap;
  line-height: 2rem;
  font-size: 1rem;
  font-weight: bold;
  transition: all 0.3s ease-in-out;

  mat-icon {
    transform: scale(0.8);
  }

  &.chip-primary {
    background-color: $fut-primary-500;
    color: white;
  }

  &.chip-secondary {
    background-color: $fut-secondary-800;
    color: white;
  }

  &.chip-outline {
    background-color: white;
    color: $fut-primary-500;
    border: 1px solid $fut-primary-500;
  }

  &.chip-success {
    background-color: $fut-green-A700;
    color: white;
  }
}

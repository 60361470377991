$x-padding: 0.93em;

.base-chip {
  --background-color: #{$fut-secondary-200};
  --color: #{$fut-primary-500};
  --border-color: var(--background-color);
  --border-radius: #{$fut-s-spacing};
  display: flex;
  align-items: center;
  width: max-content;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  color: var(--color);
  background-color: var(--background-color);

  .fut-chip-icon {
    $y-margin: 0.33em;

    font-size: 1.3em;
    width: 1em;
    height: 1em;
    margin: 0 $y-margin;

    &.left:first-child {
      margin: 0 $y-margin 0 #{$fut-s-spacing - $x-padding};
    }

    &.right:last-child {
      margin: 0 #{$fut-s-spacing - $x-padding} 0 $y-margin;
    }
  }
}

.fut-chip-label {
  @extend .base-chip;

  padding: 0.14em 0.29em;
  height: 1.86em;
  --color: rgba(#{$fut-primary-500-rgb}, 0.7);
  --border-radius: 0.21em;
  white-space: nowrap;

  &.fut-red-900-chip {
    --background-color: #{$fut-red-100};
    --color: #{$fut-red-900};
  }
}

.fut-chip-filter {
  @extend .base-chip;

  cursor: pointer;
  font-weight: 700;
  padding: 0.43em $x-padding;
  transition: all 0.3s ease-in-out;

  &:hover, &:focus, &.dragged, &[dragged] {
    --background-color: #{$fut-secondary-400};
  }

  &.dragged, &[dragged] {
    box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.15);
  }

  &:disabled, &.disabled, &[disabled] {
    &:not([disabled="false"]) {
      --background-color: #{$fut-gray-400};
      --color: rgba(#{$fut-gray-200-rgb}, 0.6);
      cursor: default;
    }
  }

  &.selected, &[selected] {
    --background-color: #{$fut-primary-500};
    --color: white;
  }
}

@keyframes background-move {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 2000% 0;
  }
}

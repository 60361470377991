$sm-size: 1.71em;
$md-size: 3.4em;

.fut-btn {
  &.fut-btn-sm {
    --y-space: 0.26em;
    --btn-height: calc(#{$sm-size} + 2 * var(--y-space));
    padding: var(--y-space) 0.71em;
    height: var(--btn-height);
    max-height: var(--btn-height);
    min-height: var(--btn-height);
  }

  &.fut-btn-md {
    height: $md-size;
    max-height: $md-size;
    min-height: $md-size;
    padding: 1em 2em;
  }

  &.fut-btn-lg {
    height: 4em;
    max-height: 4em;
    min-height: 4em;
    padding-left: 2em;
    padding-right: 2em;
    border-radius: 2em;
  }

  &.fut-btn-big {
    height: 3.5em;
    max-height: 3.5em;
    min-height: 3.5em;
    padding-left: 2em;
    padding-right: 2em;
  }
}

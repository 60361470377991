@import "@futura/futura-ui/styles/skeleton";

mat-icon.fut-skeleton {
  color: transparent;
  border-radius: 100%;
}

.fut-chip.fut-skeleton {
  min-width: 6em;
  min-height: 1.5em;
  height: 100%;
}

.opacity-pulse {
    opacity: 0;
    animation: opacityPulseAnimation 2s ease-in-out infinite;
    transition-delay: 1s;
}

@keyframes opacityPulseAnimation {
    100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    0% {
        opacity: 1;
    }
}

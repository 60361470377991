@import "../../styles/text/sizes";

.fut-chip {
  &.fut-chip-sm {
    @include fut-small;
    @include fut-bold;
  }

  &.fut-chip-lg {
    font-size: 1.21rem;
  }
}

.swiper-button-prev:after, .swiper-button-next:after {
  content: "";
  width: 30px;
  height: 30px;
  background: url("/assets/icons/icon-arrow-down.svg");
  background-size: cover;
}

.swiper-button-prev:after {
  transform: rotate(90deg) translateY(1px);
}

.swiper-button-next:after {
  transform: rotate(-90deg) translateY(1px);
}

.swiper-button-prev, .swiper-button-next {
  position: fixed !important;
  bottom: 1.5em !important;
  background: $fut-primary-500 !important;
  top: auto;
  width: 4em !important;
  height: 4em !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;

  &::after {
    font-size: 1.8em;
    color: #fff !important;
  }

  @media screen and (max-width: $mobile) {
    width: 3em !important;
    height: 3em !important;
  }
}

.swiper-button-prev {
  right: 6em;
  left: auto;
  transform: rotate(90deg);

  @media screen and (max-width: $mobile) {
    right: 5.5em;
  }
}

.swiper-button-next {
  right: 1.5em;
  left: auto;
  transform: rotate(90deg);
}

.swiper-wrapper {
  transition: all 0.3s ease-in-out !important;
}

#swiper-required-user-info-dialog {
  .swiper-slide {
    width: 100% !important;
  }
}

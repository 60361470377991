.fut-chip {
  &.fut-chip-white {
    background-color: $fut-white;
    color: rgba($fut-primary-200-rgb, 1);
  }

  &.fut-chip-gray-200 {
    background-color: $fut-gray-200;
    color: rgba($fut-primary-200-rgb, 1);
  }

  &.fut-chip-green-A700 {
    background-color: $fut-green-A700;
    color: rgba($fut-white-rgb, 1)
  }

  &.fut-chip-green-A100 {
    background-color: $fut-green-A100;
    color: rgba($fut-green-900, 1)
  }

  &.fut-chip-accent-200 {
    background-color: $fut-accent-200;
    color: rgba($fut-accent-900, 1)
  }

  &.fut-chip-red-400 {
    background-color: $fut-red-400;
    color: rgba($fut-white-rgb, 1)
  }

  &.fut-chip-red-100 {
    background-color: $fut-red-100;
    color: rgba($fut-primary-200-rgb, 1);
  }

  &.fut-chip-red-100-danger {
    background-color: $fut-red-100;
    color: rgba($fut-red-900-rgb, 1);
  }

  &.fut-chip-secondary-100 {
    background-color: $fut-secondary-100;
    color: rgba($fut-primary-200-rgb, 1);
  }

  &.fut-chip-secondary-200 {
    background-color: $fut-secondary-200;
    color: rgba($fut-primary-500-rgb, 1);
  }

  &.fut-chip-secondary-300 {
    background-color: $fut-secondary-300;
    color: rgba($fut-primary-500-rgb, 1);
  }

  &.fut-chip-secondary-400 {
    background-color: $fut-secondary-400;
    color: rgba($fut-primary-200-rgb, 1);
  }

  &.fut-chip-secondary-700 {
    background-color: $fut-secondary-700;
    color: rgba($fut-white-rgb, 1);
  }

  &.fut-chip-secondary-800 {
    background-color: $fut-secondary-800;
    color: rgba($fut-white-rgb, 1);
  }

  &.fut-chip-secondary-900 {
    background-color: $fut-secondary-900;
    color: rgba($fut-white-rgb, 1)
  }
}

.fut-card {
  background-color: white;
  border-radius: $fut-spacing-24;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: $fut-spacing-24;
  box-shadow: $shadow-normal;

  .content {
    width: 80%;
  }

  .fut-card-icon {
    display: flex;
    align-items: end;
    justify-content: center;
  }

  .fut-card-title {
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media screen and (max-width: $fut-md) {
    padding: 1.5em;
  }
}

.fut-card-secondary {
  --card-padding: var(--secondary-card-padding, 1.5em);
  box-shadow: none !important;
  border-radius: $fut-spacing-12 !important;
  padding: var(--card-padding) !important;
}
.fut-card-small {
  box-shadow: none;
  width: 100%;
  border-radius: 1em;
  padding: 1.5em;
  background-color: $fut-secondary-200;
}

.card-custom {
  border-radius: 2em;
  padding: 1.4em;
  height: 100%;
  box-shadow: 0 0 5px rgba(#000, 0.15);
  overflow: hidden;
}

:root {
  --fut-primary-50: #6F76A8,;
  --fut-primary-100: #5D6498,;
  --fut-primary-200: #475186 /* light texts i.e. placeholders */
;
  --fut-primary-300: #374475 /* icons */
;
  --fut-primary-400: #25325E /* hover on primary-500 */
;
  --fut-primary-500: #152148 /* standard text, titles etc... */
;
  --fut-primary-600: #041639,;
  --fut-primary-700: #000026,;
  --fut-primary-800: #000014,;
  --fut-primary-900: #000000,;
  --fut-primary-A100: #4875AB,;
  --fut-primary-A200: #30649C,;
  --fut-primary-A400: #00518A /* hover on primary-A700 + lines */
;
  --fut-primary-A700: #003C70 /* cards on primary-500 + ghost... */
;
  --fut-secondary-50: #F1FAFF /* highlights on hover */
;
  --fut-secondary-100: #EBF5FC /* default background */
;
  --fut-secondary-200: #E0EFFA /* cards background */
;
  --fut-secondary-300: #DAEDFB /* low priority actions */
;
  --fut-secondary-400: #C9E6FC /* hover */
;
  --fut-secondary-500: #A6DBFD /* light text on dark bg */
;
  --fut-secondary-600: #83D0FF /* hover */
;
  --fut-secondary-700: #5CB1F4 /* user data on charts dark bg */
;
  --fut-secondary-800: #379CE1 /* user data on charts light bg */
;
  --fut-secondary-900: #006DCA /* secondary hover or user chart lines */
;
  --fut-secondary-A100: #DAEDFB;
  --fut-secondary-A200: #C9E6FC;
  --fut-secondary-A400: #83D0FF;
  --fut-secondary-A700: #006DCA;
  --fut-accent-50: #FFFBC5;
  --fut-accent-100: #FFFAB2;
  --fut-accent-200: #FFF898;
  --fut-accent-300: #FFF675;
  --fut-accent-400: #FFF347;
  --fut-accent-500: #FFEE0A /* accent, primary action */
;
  --fut-accent-600: #FAE100 /* accent hover */
;
  --fut-accent-700: #E1D200;
  --fut-accent-800: #C0B300;
  --fut-accent-900: #A39800;
  --fut-accent-A100: #FFFAB2;
  --fut-accent-A200: #FFF675;
  --fut-accent-A400: #FFEE0A;
  --fut-accent-A700: #E1D200;
  --fut-green-50: #E8F5E9;
  --fut-green-100: #C8E6C9;
  --fut-green-200: #A5D6A7;
  --fut-green-300: #81C784;
  --fut-green-400: #66BB6A;
  --fut-green-500: #4CAF50;
  --fut-green-600: #43A047;
  --fut-green-700: #388E3C;
  --fut-green-800: #2E7D32;
  --fut-green-900: #1B5E20 /* Correct answer text */
;
  --fut-green-A100: #B9F6CA /* Fill or correct containers */
;
  --fut-green-A200: #69F0AE;
  --fut-green-A400: #00E676;
  --fut-green-A700: #00C853 /* correct answer + borders if needed  */
;
  --fut-red-50: #FFEBEE;
  --fut-red-100: #FFCDD2 /* Fill or errors containers / light charts with bad values */
;
  --fut-red-200: #EF9A9A;
  --fut-red-300: #E57373;
  --fut-red-400: #EF5350 /* Wrong answers icon fill / selected chart with bad values */
;
  --fut-red-500: #F44336;
  --fut-red-600: #E53935;
  --fut-red-700: #D32F2F;
  --fut-red-800: #C62828;
  --fut-red-900: #B71C1C /* Error messages text / chart borders if needed */
;
  --fut-red-A100: #FF8A80;
  --fut-red-A200: #FF5252;
  --fut-red-A400: #FF1744;
  --fut-red-A700: #D50000;
  --fut-white: #FFFFFF /* Card background */
;
  --fut-gray-200: #E2E2E3 /* Line separators */
;
  --fut-gray-400: #BDBDBD /* Disabled elements */
;
  --fut-primary-50-rgb: 111, 118, 168;
  --fut-primary-100-rgb: 93, 100, 152;
  --fut-primary-200-rgb: 71, 81, 134;
  --fut-primary-300-rgb: 55, 68, 117;
  --fut-primary-400-rgb: 37, 50, 94;
  --fut-primary-500-rgb: 21, 33, 72;
  --fut-primary-600-rgb: 4, 22, 57;
  --fut-primary-700-rgb: 0, 0, 38;
  --fut-primary-800-rgb: 0, 0, 20;
  --fut-primary-900-rgb: 0, 0, 0;
  --fut-primary-A100-rgb: 72, 117, 171;
  --fut-primary-A200-rgb: 48, 100, 156;
  --fut-primary-A400-rgb: 0, 81, 138;
  --fut-primary-A700-rgb: 0, 60, 112;
  --fut-secondary-50-rgb: 241, 250, 255;
  --fut-secondary-100-rgb: 235, 245, 252;
  --fut-secondary-200-rgb: 224, 239, 250;
  --fut-secondary-300-rgb: 218, 237, 251;
  --fut-secondary-400-rgb: 201, 230, 252;
  --fut-secondary-500-rgb: 166, 219, 253;
  --fut-secondary-600-rgb: 131, 208, 255;
  --fut-secondary-700-rgb: 92, 177, 244;
  --fut-secondary-800-rgb: 55, 156, 225;
  --fut-secondary-900-rgb: 0, 109, 202;
  --fut-secondary-A100-rgb: 218, 237, 251;
  --fut-secondary-A200-rgb: 201, 230, 252;
  --fut-secondary-A400-rgb: 131, 208, 255;
  --fut-secondary-A700-rgb: 0, 109, 202;
  --fut-accent-50-rgb: 255, 251, 197;
  --fut-accent-100-rgb: 255, 250, 178;
  --fut-accent-200-rgb: 255, 248, 152;
  --fut-accent-300-rgb: 255, 246, 117;
  --fut-accent-400-rgb: 255, 243, 71;
  --fut-accent-500-rgb: 255, 238, 10;
  --fut-accent-600-rgb: 250, 225, 0;
  --fut-accent-700-rgb: 225, 210, 0;
  --fut-accent-800-rgb: 192, 179, 0;
  --fut-accent-900-rgb: 163, 152, 0;
  --fut-accent-A100-rgb: 255, 250, 178;
  --fut-accent-A200-rgb: 255, 246, 117;
  --fut-accent-A400-rgb: 255, 238, 10;
  --fut-accent-A700-rgb: 225, 210, 0;
  --fut-green-50-rgb: 232, 245, 233;
  --fut-green-100-rgb: 200, 230, 201;
  --fut-green-200-rgb: 165, 214, 167;
  --fut-green-300-rgb: 129, 199, 132;
  --fut-green-400-rgb: 102, 187, 106;
  --fut-green-500-rgb: 76, 175, 80;
  --fut-green-600-rgb: 67, 160, 71;
  --fut-green-700-rgb: 56, 142, 60;
  --fut-green-800-rgb: 46, 125, 50;
  --fut-green-900-rgb: 185, 246, 202;
  --fut-green-A100-rgb: 0, 200, 83;
  --fut-green-A200-rgb: 105, 240, 174;
  --fut-green-A400-rgb: 0, 230, 118;
  --fut-green-A700-rgb: 27, 94, 32;
  --fut-red-50-rgb: 255, 235, 238;
  --fut-red-100-rgb: 255, 205, 210;
  --fut-red-200-rgb: 239, 154, 154;
  --fut-red-300-rgb: 229, 115, 115;
  --fut-red-400-rgb: 239, 83, 80;
  --fut-red-500-rgb: 244, 67, 54;
  --fut-red-600-rgb: 229, 57, 53;
  --fut-red-700-rgb: 211, 47, 47;
  --fut-red-800-rgb: 198, 40, 40;
  --fut-red-900-rgb: 211, 47, 47;
  --fut-red-A100-rgb: 255, 138, 128;
  --fut-red-A200-rgb: 255, 82, 82;
  --fut-red-A400-rgb: 255, 23, 68;
  --fut-red-A700-rgb: 213, 0, 0;
  --fut-white-rgb: 255, 255, 255;
  --fut-gray-200-rgb: 226, 226, 227;
  --fut-gray-400-rgb: 189, 189, 189;
  --fut-primary-alt-900-h: 208;
  --fut-primary-alt-900-s: 100%;
  --fut-primary-alt-900-l: 22%;
  color-scheme: only light;
}

.form-control {
  &:focus {
    border-color: $fut-primary-500;
    box-shadow: none;
  }

  &:invalid, &.is-invalid {
    border-color: $fut-red-900 !important;
    background-image: url("/assets/img/invalid.svg");
  }
}

.fut-input {
  border: 1px solid $fut-primary-500 !important;
  font-size: 1.6em;
  border-radius: 0.4em;
  resize: none;
  padding: 0.375rem 0.75rem;
}

.fut-input-group {
  display: flex;

  .fut-btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .mat-form-field-outline-end {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.custom-select, .custom-input {
  // width: auto
  height: 42px;
  background-color: transparent;
  border-radius: 0.6em;
  border-color: $fut-primary-500;
}

.fut-chip {
  padding: 0.3em 0.5em;
  font-weight: bold;
  color: white;
  width: fit-content;
  border-radius: 0.5em;

  mat-icon {
    --icon-dim: 1em;
    width: var(--icon-dim);
    height: var(--icon-dim);
    font-size: calc(var(--icon-dim) + 0.2em);
  }

  &.fut-chip-excellent {
    background: linear-gradient(225deg, #181886 0%, #60BDFC 100%);
    color: rgba($fut-white-rgb, 1);
  }

  &.fut-chip-perfect {
    text-transform: uppercase;
    background: url("https://images.assetsdelivery.com/compings_v2/alonastep/alonastep1602/alonastep160200364.jpg");

    &:hover {
      animation: fut-chip-background-move 50s linear infinite;
    }
  }
}

@keyframes fut-chip-background-move {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 2000% 0;
  }
}

@import "buttons/size";
@import "buttons/type";

.fut-btn {
  height: 2.85em;
  max-height: 2.85em;
  min-height: 2.85em;
  font-weight: 700;
  border-radius: $fut-spacing-12;
  cursor: pointer;
  padding-left: 1.7em;
  padding-right: 1.7em;
  width: max-content;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s ease-in-out;

  &:has(mat-icon) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &[disabled], &.disabled {
    cursor: not-allowed;
  }

  &:hover, &:not(:hover) {
    transition: none;
  }
}

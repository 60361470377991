.fut-chip-filter.fut-chip-outline {
  --background-color: transparent;
  --border-color: rgba(#{$fut-gray-200-rgb}, 0.6);

  &:hover {
    --background-color: #{$fut-secondary-200};
  }

  &:focus, &.dragged, &[dragged] {
    --background-color: #{$fut-secondary-400};
  }

  &:disabled, &.disabled, &[disabled] {
    &:not([disabled="false"]) {
      $disabled-color: $fut-gray-400;

      --color: #{$disabled-color};
      --border-color: #{$disabled-color};
      --background-color: transparent;
    }
  }

  &.selected, &[selected] {
    --color: #{$fut-secondary-800};
    --background-color: transparent;
  }
}

@use "material-variable" as var;

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

mat-form-field {
  --mdc-shape-small: #{var.$border-radius};
  --mdc-outlined-text-field-container-shape: #{var.$border-radius};
  width: 100%;
}

.mat-mdc-input-element {
  line-height: 1.71em !important;
}

mat-checkbox {
  label {
    margin: 0;
  }

  .mat-mdc-checkbox-ripple, .mdc-checkbox__ripple {
    display: none !important;
  }
}

mat-radio-button {
  .mdc-radio {
    padding: 0.5em;
  }

  .mdc-form-field {
    label {
      margin: 0;
    }
  }

  .mat-radio-ripple,
  .mdc-radio__background::before {
    display: none !important;
  }
}

// sidenav tooltip customization
.fut-sidenav-tooltip.tooltip {
  min-width: 10em;

  .tooltip-inner {
    height: 3.4em;
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
    overflow: hidden;
    min-height: 3em;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-left: 0.8em;
    font-weight: bold;
    letter-spacing: 1px;
    background: #152148;
    font-size: 1.2em;
  }

  &.show {
    opacity: 1;
  }

  &.bs-tooltip-right .arrow::before, &.bs-tooltip-auto[x-placement^=right] .arrow::before {
    // border-right-color: $fut-secondary-800
    display: none;
  }
}

// sidenav tooltip customization

.fut-link {
  cursor: pointer;
  color: $fut-primary-500;
  text-decoration: underline;
  transition: all 0.1s ease-in-out;

  &:hover {
    color: $fut-secondary-800;
  }
}

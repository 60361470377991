.futura-data-picker {
  width: 100% !important;
  min-width: 100% !important;

  input {
    min-width: calc(100% - 1.1em) !important;
    width: 100% !important;
    height: 3.5em !important;
    padding-left: 0.5em;
    padding-right: 0.5em;
    background-color: white !important;
    color: black !important;
    border: 1px solid rgba($fut-primary-500-rgb, 0.6);
    border-radius: 0.5em;

    &:active, &:focus {
      border-color: $fut-secondary-800;
    }
  }
}

// new
.mat-mdc-dialog-panel {

  // made dialog bigger in mobile
  @media screen and (max-width: $mobile) {
    --mat-dialog-container-small-max-width: 90vw;
    width: max-content !important;
  }
}

.mat-mdc-dialog-surface {
  padding: 2em !important;
  border-radius: 2em !important;
}

.fut-dialog-basic {
  max-height: 100svh;
  --paddingfut-dialog: 2.14em;

  mat-dialog-container {
    font-size: 14px;
    border-radius: var(--paddingfut-dialog) !important;
    overflow: hidden;
    box-shadow: none;
    padding: 0;

    .mat-mdc-dialog-surface {
      padding: var(--paddingfut-dialog) !important;
    }

    .mat-mdc-dialog-title {
      padding: 0;
      font-size: 14px;

      &:before {
        content: none;
      }
    }

    .mat-mdc-dialog-content {
      margin: 0 calc(-1 * var(--paddingfut-dialog));
      padding: 0 var(--paddingfut-dialog);
      --mdc-dialog-subhead-color: var(--fut-primary-500);
      --mdc-dialog-supporting-text-color: var(--fut-primary-500);
    }
  }

  @media screen and (max-width: $fut-md) {
    --paddingfut-dialog: 1.43em;
    margin: 0 0.5em;
  }
}

@mixin fut-dialog($max-w) {
  @extend .fut-dialog-basic;

  max-width: $max-w !important;
  width: $max-w !important;
}

.fut-dialog-sm {
  @include fut-dialog(27.8em);
}

.fut-dialog-md {
  @include fut-dialog(37.86em);
}

.fut-dialog-lg {
  @include fut-dialog(49.3em);
}

.fut-dialog-xl {
  @include fut-dialog(73.13em);
}

.cdk-overlay-container {
  &:has(*.fut-dialog-sm), &:has(*.fut-dialog-md), &:has(*.fut-dialog-lg), &:has(*.fut-dialog-xl), &:has(*.fut-dialog-basic), &:has(*.fut-menu-info-bg) {
    overflow-y: hidden !important;
  }
}

.fut-mat-dialog-transparent {
  .mat-mdc-dialog-container {
    background: transparent !important;
    box-shadow: none !important;
    overflow: hidden !important;
  }
}

.dialog-overflow-hidden {
  mat-dialog-container {
    overflow: hidden !important;
  }
}

.dialog-overflow-hidden-x {
  mat-dialog-container {
    overflow-x: hidden !important;
  }
}

.mat-mdc-dialog-title {
  font-size: 14px;
}

.mat-mdc-dialog-container {
  border-radius: 2em !important;

  @media screen and (max-width: $mobile) {
    padding: 0 !important;

    .fut-h1 {
      font-size: 1.64em;
    }
  }
}

.mat-mdc-dialog-title {
  font-weight: bold;
  margin-bottom: 0 !important;
}

.mat-mdc-dialog-content {
  .overflow-auto {
    overflow-x: hidden !important;
    position: absolute;
    left: 0;
    width: 100%;
    max-height: 80vh;
    padding-bottom: 3em;

    @media screen and (max-width: $mobile) {
      max-height: 100%;
      padding-bottom: 4em;
    }
  }
}

.invisible-backdrop {
  opacity: 0 !important;
}

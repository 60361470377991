// new
// global
.mat-mdc-menu-panel {
  border-radius: 1em !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 0 2px 6px 2px rgba(0, 0, 0, 0.15) !important;
  padding: 1em !important;
  max-height: 30em !important;
  position: relative !important;
  max-width: max-content !important;
  overflow: hidden !important;

  .mat-mdc-menu-content {
    padding: 0 !important;
  }
}

// global

.fut-menu-info-bg {
  opacity: 0 !important;
}

.mat-mdc-menu-panel.fut-menu-info-content {
  --dialog-distance: #{$fut-spacing-24};
  max-width: 35.7em !important;
  max-height: fit-content !important;
  margin: 1em 0.5em;
  padding: 0 !important;
  border-radius: var(--dialog-distance) !important;
  overflow: hidden;

  fut-base-dialog {
    display: block;
    padding: var(--dialog-distance);
    overflow: hidden;

    .mat-mdc-dialog-title {
      font-size: 14px;
    }

    .mat-mdc-dialog-content {
      margin: 0 calc(var(--dialog-distance) * -1);
      padding: 0 var(--dialog-distance);
      max-height: 72vh;
      overflow: auto;
    }
  }

  @media screen and (max-width: $fut-md) {
    max-width: 25.3em !important;
    margin: 1em;
  }
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.capitalize {
  text-transform: capitalize;
}

.text-scrolling-wrapper {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  --text-scrolling-100perc-translateX: -40%;
  --text-scrolling-duration: 3s;

  &:hover {
    .text-scrolling-animation-active {
      display: inline-block;
      animation-name: text-scrolling-animation;
      animation-duration: var(--text-scrolling-duration);
      animation-timing-function: linear;
      animation-delay: 0.5s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
  }
}


/* text scrolling hover animation */
.text-scrolling-hover-perimeter {
  &:hover {
    .text-scrolling-wrapper .text-scrolling-animation-active {
      display: inline-block;
      animation-name: text-scrolling-animation;
      animation-duration: var(--text-scrolling-duration);
      animation-timing-function: linear;
      animation-delay: 0.5s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
  }
}

@keyframes text-scrolling-animation {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(var(--text-scrolling-100perc-translateX));
  }
}

@import "@futura/futura-ui/styles/material-customization";

.onboarding-dialogs {
  background-image: $bg-image !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
  position: relative;

  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px) !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
  }

  input {
    font-size: 14px !important;
    height: 4em !important;
    border: 1px solid rgba(33, 37, 41, 0.6) !important;
  }
}

.onboarding-panel-dialogs {
  width: 30vw !important;
  max-width: 30vw !important;

  // Media query to large device
  @media screen and (max-width: $mobile) {
    width: 98vw !important;
    max-width: 98vw !important;
  }

  @media screen and (min-width: $mobile) and (max-width: $tablet-portrait) {
    width: 60vw !important;
    max-width: 60vw !important;
  }
}

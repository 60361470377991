.mat-calendar-body-selected {
  background-color: $fut-primary-500 !important;
  color: white;
}

.mat-calendar-body-cell {
  .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
    background-color: transparent !important;
  }

  &:hover {
    .mat-calendar-body-cell-content {
      background-color: rgba($fut-primary-500-rgb, 0.3) !important;
    }
  }
}

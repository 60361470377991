
.fut-skeleton {
  --skeleton-background-color: #{$fut-secondary-400};
  --skeleton-loading-h: 203;
  --skeleton-loading-s: 86%;
  --skeleton-loading-l: 92%;
  display: inline-block;
  mix-blend-mode: multiply;
  color: transparent;

  &:not(.ghost) {
    animation: skeleton-loading 1s infinite alternate;
  }

  &.ghost {
    background-color: var(--skeleton-background-color) !important;
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(var(--skeleton-loading-h), var(--skeleton-loading-s), var(--skeleton-loading-l));
  }

  100% {
    background-color: hsl(var(--skeleton-loading-h), var(--skeleton-loading-s), calc(var(--skeleton-loading-l) + 15));
  }
}

.fut-skeleton.fut-skeleton-text {
  width: 100%;
  min-width: 1em;
  height: 0.7rem;
  border-radius: 0.25rem;
}

.fut-skeleton.fut-skeleton-circle {
  width: 100%;
  min-width: 1em;
  aspect-ratio: 1 / 1;
  border-radius: 100%;
}

@for $w from 1 through 100 {
  .fut-skeleton.fut-skeleton-#{$w}w {
    width: $w + unquote('%');
  }
}

$fut-primary-50: var(--fut-primary-50);
$fut-primary-100: var(--fut-primary-100);
$fut-primary-200: var(--fut-primary-200);
$fut-primary-300: var(--fut-primary-300);
$fut-primary-400: var(--fut-primary-400);
$fut-primary-500: var(--fut-primary-500);
$fut-primary-600: var(--fut-primary-600);
$fut-primary-700: var(--fut-primary-700);
$fut-primary-800: var(--fut-primary-800);
$fut-primary-900: var(--fut-primary-900);
$fut-primary-A100: var(--fut-primary-A100);
$fut-primary-A200: var(--fut-primary-A200);
$fut-primary-A400: var(--fut-primary-A400);
$fut-primary-A700: var(--fut-primary-A700);

$fut-secondary-50: var(--fut-secondary-50);
$fut-secondary-100: var(--fut-secondary-100);
$fut-secondary-200: var(--fut-secondary-200);
$fut-secondary-300: var(--fut-secondary-300);
$fut-secondary-400: var(--fut-secondary-400);
$fut-secondary-500: var(--fut-secondary-500);
$fut-secondary-600: var(--fut-secondary-600);
$fut-secondary-700: var(--fut-secondary-700);
$fut-secondary-800: var(--fut-secondary-800);
$fut-secondary-900: var(--fut-secondary-900);
$fut-secondary-A100: var(--fut-secondary-A100);
$fut-secondary-A200: var(--fut-secondary-A200);
$fut-secondary-A400: var(--fut-secondary-A400);
$fut-secondary-A700: var(--fut-secondary-A700);

$fut-accent-50: var(--fut-accent-50);
$fut-accent-100: var(--fut-accent-100);
$fut-accent-200: var(--fut-accent-200);
$fut-accent-300: var(--fut-accent-300);
$fut-accent-400: var(--fut-accent-400);
$fut-accent-500: var(--fut-accent-500);
$fut-accent-600: var(--fut-accent-600);
$fut-accent-700: var(--fut-accent-700);
$fut-accent-800: var(--fut-accent-800);
$fut-accent-900: var(--fut-accent-900);
$fut-accent-A100: var(--fut-accent-A100);
$fut-accent-A200: var(--fut-accent-A200);
$fut-accent-A400: var(--fut-accent-A400);
$fut-accent-A700: var(--fut-accent-A700);

$fut-green-50: var(--fut-green-50);
$fut-green-100: var(--fut-green-100);
$fut-green-200: var(--fut-green-200);
$fut-green-300: var(--fut-green-300);
$fut-green-400: var(--fut-green-400);
$fut-green-500: var(--fut-green-500);
$fut-green-600: var(--fut-green-600);
$fut-green-700: var(--fut-green-700);
$fut-green-800: var(--fut-green-800);
$fut-green-900: var(--fut-green-900);
$fut-green-A100: var(--fut-green-A100);
$fut-green-A200: var(--fut-green-A200);
$fut-green-A400: var(--fut-green-A400);
$fut-green-A700: var(--fut-green-A700);

$fut-red-50: var(--fut-red-50);
$fut-red-100: var(--fut-red-100);
$fut-red-200: var(--fut-red-200);
$fut-red-300: var(--fut-red-300);
$fut-red-400: var(--fut-red-400);
$fut-red-500: var(--fut-red-500);
$fut-red-600: var(--fut-red-600);
$fut-red-700: var(--fut-red-700);
$fut-red-800: var(--fut-red-800);
$fut-red-900: var(--fut-red-900);
$fut-red-A100: var(--fut-red-A100);
$fut-red-A200: var(--fut-red-A200);
$fut-red-A400: var(--fut-red-A400);
$fut-red-A700: var(--fut-red-A700);

$fut-white: var(--fut-white);
$fut-gray-200: var(--fut-gray-200);
$fut-gray-400: var(--fut-gray-400);

$fut-colors: (
  text: $fut-primary-500,
  light: $fut-primary-200,
  primary: $fut-primary-500,
  primary-alt-900: $fut-primary-A700,
  secondary: $fut-secondary-800,
  secondary-900: $fut-secondary-900,
  tertiary: $fut-secondary-400,
  quaternary: $fut-secondary-200,
  accent: $fut-accent-500,
  accent-light: $fut-accent-600,
  body: $fut-secondary-100,
  highlight: $fut-secondary-50,
  error: $fut-red-900,
  error-light: $fut-red-400,
  error-lightest: $fut-red-100,
  error-bg: $fut-red-100,
  valid: $fut-green-A700,
  disabled: $fut-gray-400
);

$fut-primary-50-rgb: var(--fut-primary-50-rgb);
$fut-primary-100-rgb: var(--fut-primary-100-rgb);
$fut-primary-200-rgb: var(--fut-primary-200-rgb);
$fut-primary-300-rgb: var(--fut-primary-300-rgb);
$fut-primary-400-rgb: var(--fut-primary-400-rgb);
$fut-primary-500-rgb: var(--fut-primary-500-rgb);
$fut-primary-600-rgb: var(--fut-primary-600-rgb);
$fut-primary-700-rgb: var(--fut-primary-700-rgb);
$fut-primary-800-rgb: var(--fut-primary-800-rgb);
$fut-primary-900-rgb: var(--fut-primary-900-rgb);
$fut-primary-A100-rgb: var(--fut-primary-A100-rgb);
$fut-primary-A200-rgb: var(--fut-primary-A200-rgb);
$fut-primary-A400-rgb: var(--fut-primary-A400-rgb);
$fut-primary-A700-rgb: var(--fut-primary-A700-rgb);

$fut-secondary-50-rgb: var(--fut-secondary-50-rgb);
$fut-secondary-100-rgb: var(--fut-secondary-100-rgb);
$fut-secondary-200-rgb: var(--fut-secondary-200-rgb);
$fut-secondary-300-rgb: var(--fut-secondary-300-rgb);
$fut-secondary-400-rgb: var(--fut-secondary-400-rgb);
$fut-secondary-500-rgb: var(--fut-secondary-500-rgb);
$fut-secondary-600-rgb: var(--fut-secondary-600-rgb);
$fut-secondary-700-rgb: var(--fut-secondary-700-rgb);
$fut-secondary-800-rgb: var(--fut-secondary-800-rgb);
$fut-secondary-900-rgb: var(--fut-secondary-900-rgb);
$fut-secondary-A100-rgb: var(--fut-secondary-A100-rgb);
$fut-secondary-A200-rgb: var(--fut-secondary-A200-rgb);
$fut-secondary-A400-rgb: var(--fut-secondary-A400-rgb);
$fut-secondary-A700-rgb: var(--fut-secondary-A700-rgb);

$fut-accent-50-rgb: var(--fut-accent-50-rgb);
$fut-accent-100-rgb: var(--fut-accent-100-rgb);
$fut-accent-200-rgb: var(--fut-accent-200-rgb);
$fut-accent-300-rgb: var(--fut-accent-300-rgb);
$fut-accent-400-rgb: var(--fut-accent-400-rgb);
$fut-accent-500-rgb: var(--fut-accent-500-rgb);
$fut-accent-600-rgb: var(--fut-accent-600-rgb);
$fut-accent-700-rgb: var(--fut-accent-700-rgb);
$fut-accent-800-rgb: var(--fut-accent-800-rgb);
$fut-accent-900-rgb: var(--fut-accent-900-rgb);
$fut-accent-A100-rgb: var(--fut-accent-A100-rgb);
$fut-accent-A200-rgb: var(--fut-accent-A200-rgb);
$fut-accent-A400-rgb: var(--fut-accent-A400-rgb);
$fut-accent-A700-rgb: var(--fut-accent-A700-rgb);

$fut-green-50-rgb: var(--fut-green-50-rgb);
$fut-green-100-rgb: var(--fut-green-100-rgb);
$fut-green-200-rgb: var(--fut-green-200-rgb);
$fut-green-300-rgb: var(--fut-green-300-rgb);
$fut-green-400-rgb: var(--fut-green-400-rgb);
$fut-green-500-rgb: var(--fut-green-500-rgb);
$fut-green-600-rgb: var(--fut-green-600-rgb);
$fut-green-700-rgb: var(--fut-green-700-rgb);
$fut-green-800-rgb: var(--fut-green-800-rgb);
$fut-green-900-rgb: var(--fut-green-900-rgb);
$fut-green-A100-rgb: var(--fut-green-A100-rgb);
$fut-green-A200-rgb: var(--fut-green-A200-rgb);
$fut-green-A400-rgb: var(--fut-green-A400-rgb);
$fut-green-A700-rgb: var(--fut-green-A700-rgb);

$fut-red-50-rgb: var(--fut-red-50-rgb);
$fut-red-100-rgb: var(--fut-red-100-rgb);
$fut-red-200-rgb: var(--fut-red-200-rgb);
$fut-red-300-rgb: var(--fut-red-300-rgb);
$fut-red-400-rgb: var(--fut-red-400-rgb);
$fut-red-500-rgb: var(--fut-red-500-rgb);
$fut-red-600-rgb: var(--fut-red-600-rgb);
$fut-red-700-rgb: var(--fut-red-700-rgb);
$fut-red-800-rgb: var(--fut-red-800-rgb);
$fut-red-900-rgb: var(--fut-red-900-rgb);
$fut-red-A100-rgb: var(--fut-red-A100-rgb);
$fut-red-A200-rgb: var(--fut-red-A200-rgb);
$fut-red-A400-rgb: var(--fut-red-A400-rgb);
$fut-red-A700-rgb: var(--fut-red-A700-rgb);

$fut-white-rgb: var(--fut-white-rgb);
$fut-gray-200-rgb: var(--fut-gray-200-rgb);
$fut-gray-400-rgb: var(--fut-gray-400-rgb);

$fut-colors-rgb: (text: $fut-primary-500-rgb, primary: $fut-primary-500-rgb, primary-alt-900: $fut-primary-A700-rgb, secondary: $fut-secondary-800-rgb, tertiary: $fut-secondary-400-rgb, quaternary: $fut-secondary-200-rgb, accent: $fut-accent-500-rgb, accent-light: $fut-accent-600-rgb, body: $fut-secondary-100-rgb, highlight: $fut-secondary-50-rgb, error: $fut-red-900-rgb, error-light: $fut-red-400-rgb, error-lightest: $fut-red-100-rgb, error-bg: $fut-red-100-rgb, valid: $fut-green-A700-rgb, disabled: $fut-gray-400-rgb, blacks-text-line-borders: $fut-gray-200-rgb);

// Gradients
$fut-white-grad: $fut-white repeating-linear-gradient(-45deg, $fut-white, $fut-white 5px, $fut-secondary-500 5px, $fut-secondary-500 6px);
$fut-primary-A700-grad: $fut-primary-A700 repeating-linear-gradient(-45deg, $fut-primary-A700, $fut-primary-A700 5px, $fut-secondary-900 5px, $fut-secondary-900 6px);
$fut-secondary-200-grad: $fut-secondary-200 repeating-linear-gradient(-45deg, $fut-secondary-200, $fut-secondary-200 5px, $fut-secondary-500 5px, $fut-secondary-500 6px);
